import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import store from '../../../../../redux/Store';
import {
  HANDLE_IS_OPEN_VALIDATION_MODAL,
  HANDLE_RENDER_ATTRIBUTE,
  HANDLE_IGNORE_WARNINGS,
} from '../../../../../redux/actions/Actions';

import { Filter } from './Filter';
import { Accordion } from './Accordion';
import { Header } from './Header';
import './validation.css';
import WarningModal from './WarningModal';

class ValidationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pos: { x: window.innerWidth / 2 - 450, y: 150 },
      dragging: false,
      rel: null,
      resizeFlag: false,
      errorCheck: true,
      warningCheck: true,
      announcementCheck: true,
      validations: [],
      activeAccordion: 0,
      nbbTemplate: [],
      warningMsg: false,
      info: null,
      isErrorExists: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.dragging && !prevState.dragging) {
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    } else if (!this.state.dragging && prevState.dragging) {
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.onMouseUp);
    }
  }

  componentWillUnmount() {
    this.closeModal();
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (!this.state.resizeFlag) {
        this.setState({
          resizeFlag: true,
          pos: {
            x: window.innerWidth - 587 - 25,
            y: window.innerHeight - 380 > 0 ? window.innerHeight - 380 : 0,
          },
        });
      }
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  onMouseDown = (e) => {
    if (e.button !== 0) return;
    const elem = e.target;
    const pos = elem.getBoundingClientRect();

    this.setState({
      dragging: true,
      rel: {
        x: e.pageX - pos.left,
        y: e.pageY - pos.top > 0 ? e.pageY - pos.top : 0,
      },
    });
    e.stopPropagation();
    e.preventDefault();
  };

  onMouseUp = (e) => {
    this.setState({ dragging: false });
    e.stopPropagation();
    e.preventDefault();
  };

  onMouseMove = (e) => {
    if (!this.state.dragging) return;
    const dialogWidth = document.getElementById('node').clientWidth;
    const dialogHeight = document.getElementById('node').clientHeight;
    const x = Math.min(
      Math.max(0, e.pageX - this.state.rel.x),
      window.innerWidth - dialogWidth - 20,
    );
    const y = Math.min(
      Math.max(0, e.pageY - this.state.rel.y),
      window.innerHeight - dialogHeight - 20,
    );
    this.setState({
      pos: {
        x,
        y: y > 0 ? y : 0,
      },
    });
    e.stopPropagation();
    e.preventDefault();
  };

  handleResize = () => {
    this.setState({ resizeFlag: !this.state.resizeFlag }, () => {
      if (this.state.resizeFlag) {
        this.setState({
          pos: {
            x: window.innerWidth - 587 - 25,
            y: window.innerHeight - 380 > 0 ? window.innerHeight - 380 : 0,
          },
        });
      } else {
        this.setState({
          pos: {
            x: window.innerWidth / 2 - 450,
            y: 150,
          },
        });
      }
    });
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.validations && !props.isValidationDataChange) {
      this.setState({
        validations: props.validations,
        isErrorExists: this.isValidationErrorExists(props.validations),
      });
    }
  }

  isValidationErrorExists = (validations) => {
    let isErrorExists = false;
    for (const validation of validations) {
      for (const vData of validation.data) {
        if (vData && vData.type == 'error') {
          isErrorExists = true;
          break;
        }
      }
      if (isErrorExists) {
        break;
      }
    }
    return isErrorExists;
  };

  resolveMandatoryField = (attrData, gtin) => {
    const validationData = JSON.parse(JSON.stringify(this.state.validations));
    const productAttrValidation = validationData.filter((v) => v.gtin == gtin)[0];

    const attributeValidations = productAttrValidation.data;
    const index = attributeValidations.findIndex((x) => x.uniqueId === attrData.uniqueId);
    attributeValidations.splice(index, 1);
    validationData.map((v) => {
      if (v.gtin == gtin) {
        v.data = attributeValidations;
      }
      return v;
    });

    this.setState({
      validations: validationData,
    }, () => {
      store.dispatch({ type: 'SET_VALIDATION_MODAL_IS_CHANGE', data: true });
    });
  };

  closeModal = () => {
    store.dispatch({ type: HANDLE_IS_OPEN_VALIDATION_MODAL, data: false });
    this.setState(
      {
        resizeFlag: false,
        errorCheck: true,
        warningCheck: true,
        announcementCheck: true,
      },
      () => {
        store.dispatch({ type: 'SET_VALIDATION_MODAL_IS_CHANGE', data: false });
        this.setState({ pos: { x: window.innerWidth / 2 - 450, y: 150 } });
      },
    );
    this.props.history.push({
      // pathname: '',
      state: {
        // attributeInfo: {}
      },
    });
  };

  filterValidation = (checkStatus, fieldName) => {
    this.setState({
      [fieldName]: !checkStatus,
    });
  };

  getIndex = (key, keyPath) => {
    for (const i in keyPath) {
      if (keyPath[i] === key) {
        return keyPath[Number(i) + 1];
      }
    }
  };

  getData = (obj, path) => {
    const keyPath = path?.split('/');
    const filtered = keyPath.filter((item) => isNaN(Number(item)));
    const lastKeyIndex = filtered?.length - 1;
    for (let i = 0; i < lastKeyIndex; ++i) {
      const key = filtered[i];
      if (!(key in obj)) {
        obj[key] = {};
      }
      obj = !Array.isArray(obj[key])
        ? obj[key]
        : obj[key][this.getIndex(key, keyPath)];
    }
    return Array?.isArray(obj[filtered[lastKeyIndex]])
      ? obj[filtered[lastKeyIndex]][keyPath[keyPath?.length - 1]]
      : obj[filtered[lastKeyIndex]];
  };

  handleUpdatedFormData = () => {
    const p = [];
    const visitNodes = (obj, stack = []) => {
      if (
        obj && typeof obj === 'object'
        && Object.keys(obj)?.length > 0
        && !obj?.value
      ) {
        for (const key in obj) {
          visitNodes(obj[key], [...stack, key]);
        }
      } else {
        obj?.value
          && p.push(stack.join('/').replace(/(?:\.)(\d+)(?![a-z_])/gi, '/$1'));
      }
    };

    visitNodes(this.props.location.state.updatedFormData);

    const q = [];
    const visitCurrentDataNodes = (obj, stack = []) => {
      if (
        typeof obj === 'object'
        && Object.keys(obj)?.length > 0
        && !obj?.value
      ) {
        for (const key in obj) {
          visitCurrentDataNodes(obj[key], [...stack, key]);
        }
      } else {
        obj?.value
          && q.push(stack.join('/').replace(/(?:\.)(\d+)(?![a-z_])/gi, '/$1'));
      }
    };

    visitCurrentDataNodes(this.props.location.state.currentProductData);
    if (p.length !== q.length) {
      return true;
    } if (p.length > 0 && q.length > 0 && p.length === q.length) {
      for (let i = 0; i < q.length; i++) {
        if (!p.includes(q[i]) || !q.includes(p[i])) {
          return true;
        }
        if (
          !Array.isArray(
            this.getData(
              this.props.history.location.state.updatedFormData,
              q[i],
            )?.value,
          )
            && this.getData(
              this.props.history.location.state.updatedFormData,
              q[i],
            )?.value
            !== this.getData(
              this.props.history.location.state.currentProductData,
              q[i],
            )?.value
        ) {
          return true;
        }
      }
    }
  };

  handleWarningMsg = (data, attrInfo, ruleId) => {
    if (data) {
      this.setState({ warningMsg: true, info: attrInfo });
    } else {
      this.props.history.push({
        pathname: `/nbb-products/edit/${attrInfo.productId}`,
        state: {
          attributeInfo: { ...attrInfo, pimRule_id: ruleId },
        },
      });
    }
  };

  renderToAttribute = (attrData, ruleId) => {
    const attrInfo = JSON.parse(JSON.stringify(attrData));
    attrInfo.randomId = Math.random();
    if (!this.state.resizeFlag) {
      this.setState({ resizeFlag: true }, () => {
        this.setState({
          pos: {
            x: window.innerWidth - 587 - 25,
            y: window.innerHeight - 615 > 0 ? window.innerHeight - 615 : 0,
          },
        });
      });
    }
    if (
      !this?.props?.location?.state
      || this?.props?.location?.state?.productId === attrInfo.productId
    ) {
      if (this?.props?.location?.state?.updatedFormData) {
        this.props.history.push({
          pathname: `/nbb-products/edit/${attrInfo.productId}`,
          state: {
            attributeInfo: { ...attrInfo, pimRule_id: ruleId },
            updatedFormData: this.props.location.state?.updatedFormData,
            currentProductData: this.props.location.state?.currentProductData,
            productId: this.props?.location?.state?.productId,
          },
        });
      } else {
        this.props.history.push({
          pathname: `/nbb-products/edit/${attrInfo.productId}`,
          state: {
            attributeInfo: { ...attrInfo, pimRule_id: ruleId },
          },
        });
      }
    } else {
      this.handleWarningMsg(this.handleUpdatedFormData(), attrInfo, ruleId);
    }
    store.dispatch({
      type: HANDLE_RENDER_ATTRIBUTE,
      data: attrInfo,
    });
  };

  handleToggleAccordion = (accIndex) => {
    this.setState({
      activeAccordion: this.state.activeAccordion !== accIndex ? accIndex : -1,
    });
  };

  ignoreWarning = (e) => {
    store.dispatch({
      type: HANDLE_IGNORE_WARNINGS,
      ignoreWarning: true,
    });
  };

  isVisibleIgnoreOpt = (data) => {
    const newErrorData = [];
    data && data.map((item) => item?.data?.map((val) => {
      newErrorData.push(val);
    }));
    if (newErrorData.length === 0) {
      return false;
    }
    return true;
  };

  render() {
    const {
      t, isOpen, isApprove, isSaveAndApprove, retailers, template, componentTemplate,
    } = this.props;
    return (
      isOpen && (
        <>
          <div
            id="node"
            ref={this.setWrapperRef}
            className="nbb custom-validation-modal edit-validation"
          >
            <Header
              t={t}
              resizeFlag={this.state.resizeFlag}
              onMouseDown={this.onMouseDown}
              handleResize={this.handleResize}
              closeModal={this.closeModal}
            />
            <div className="body">
              <Filter
                t={t}
                filterValidation={this.filterValidation}
                errorCheck={this.state.errorCheck}
                warningCheck={this.state.warningCheck}
                announcementCheck={this.state.announcementCheck}
              />
              <div className="card-body">
                {this.state.validations
                  && this.state.validations.map((vData, index) => {
                    const productValidationData = { ...vData, productId: vData.id };
                    return (
                      <Accordion
                        t={t}
                        key={`${vData.gtin}_${index}`}
                        data={productValidationData}
                        accordionIndex={index}
                        activeAccordion={this.state.activeAccordion}
                        toggleAction={this.state.toggleAction}
                        errorCheck={this.state.errorCheck}
                        warningCheck={this.state.warningCheck}
                        announcementCheck={this.state.announcementCheck}
                        resolveMandatoryField={this.resolveMandatoryField}
                        renderToAttribute={this.renderToAttribute}
                        handleToggleAccordion={this.handleToggleAccordion}
                        errorMsgTranslations={this.props.errorMsgTranslations}
                        retailers={retailers}
                        template={template}
                        componentTemplate={componentTemplate}
                      />
                    );
                  })}
              </div>
            </div>
            {(isApprove || isSaveAndApprove) && !this.state.isErrorExists && this.isVisibleIgnoreOpt(this.state.validations) ? (
              <div className="validation_footer">
                <div className="footer_content">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => this.ignoreWarning(e)}
                  >
                    {t('Ignore & Continue')}
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <style jsx="true">
            {`
            .custom-validation-modal {
              height: ${this.state.resizeFlag ? '380px' : '630px'};
              width: ${this.state.resizeFlag ? '587px' : '1050px'};
              left: ${this.state.pos.x}px;
              top: ${this.state.pos.y}px;
              display: ${isOpen ? 'block' : 'none'};
              border: 2px solid #cdcdcd;
            }
            .body {
              max-height: ${this.state.resizeFlag
              ? '370px !important'
              : '560px'};
            }
          `}
          </style>
          {this.state?.warningMsg && (
            <WarningModal
              isOpen={this.state.warningMsg}
              t={t}
              closeModal={() => this.setState({
                warningMsg: false,
              })}
              handleApproveAndContinue={() => {
                this.setState({ warningMsg: false });
                this.props.history.push({
                  pathname: `/nbb-products/edit/${this.state?.info?.productId}`,
                  state: {
                    attributeInfo: this.state.info,
                    saveCurrentProduct: true,
                  },
                });
              }}
            />
          )}
        </>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.isValidation,
  validations: state.validationData,
  isApprove: state.isApprove,
  isSaveAndApprove: state.isSaveAndApprove,
  productId: state.productId,
  retailers: state.retailers,
  template: state.template,
  componentTemplate: state.componentTemplate,
  codeList: state.codeList,
  errorMsgTranslations: state.errorMsgTranslations,
  isValidationDataChange: state.isValidationDataChange,
});

export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(ValidationModal)),
);
