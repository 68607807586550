import { checkTPDAtClassLevel } from '../utils';

import { isRepeatableByClass } from './GDSNDataTypesConfigration';

export const handleSortNbbTemplate = (data) => {
  const templateData = (sortModules(data))?.map((item) => {
    if (item.name == 'identity') {
      return item;
    }
    if (item.children && item.children.length !== 0) {
      item.children.map((value) => {
        recursiveFunction(value);
      });
      item.children.sort((a, b) => {
        if (
          typeof a.displayOrder === 'number'
            && typeof b.displayOrder === 'number'
        ) {
          return a.displayOrder - b.displayOrder;
        } if (
          typeof a.displayOrder === 'number'
            && b.displayOrder == null
        ) {
          return -1;
        } if (
          a.displayOrder == null
            && typeof b.displayOrder === 'number'
        ) {
          return 1;
        }
        return a.label > b.label ? 1 : -1;
      });
    }

    return item;
  });
  return templateData;
};

export const recursiveFunction = (data) => {
  if (data.name == 'identity') {
    return data;
  }
  if (data.children && data.children.length !== 0) {
    data.children.map((value) => {
      recursiveFunction(value);
    });
    data.children.sort((a, b) => {
      if (
        typeof a.displayOrder === 'number'
          && typeof b.displayOrder === 'number'
      ) {
        return a.displayOrder - b.displayOrder;
      } if (
        typeof a.displayOrder === 'number'
          && b.displayOrder == null
      ) {
        return -1;
      } if (
        a.displayOrder == null
          && typeof b.displayOrder === 'number'
      ) {
        return 1;
      }
      return a.label > b.label ? 1 : -1;
    });
  }

  return data;
};

export const sortModules = (data) => {
  data.sort((a, b) => {
    if (a.name == 'identity' || b.name == 'identity') {
      return 1;
    } if (
      typeof a.displayOrder === 'number'
          && typeof b.displayOrder === 'number'
    ) {
      return a.displayOrder - b.displayOrder;
    } if (
      typeof a.displayOrder === 'number'
          && b.displayOrder == null
    ) {
      return -1;
    } if (
      a.displayOrder == null
          && typeof b.displayOrder === 'number'
    ) {
      return 1;
    }
    return a.label > b.label ? 1 : -1;
  });

  return data;
};

export const handleSortLogicalGrouping = (data, logicalGroupingDisplay) => {
  data.sort((a, b) => {
    if (a?.length > 0 && (a[0]?.logicalGrouping === 'Identity' || b[0]?.logicalGrouping == 'Identity')) {
      return 1;
    } if (
      a?.length > 0 && typeof logicalGroupingDisplay[a[0]?.logicalGrouping] === 'number'
     && b?.length > 0 && typeof logicalGroupingDisplay[b[0]?.logicalGrouping] === 'number'
    ) {
      return logicalGroupingDisplay[a[0]?.logicalGrouping] - logicalGroupingDisplay[b[0]?.logicalGrouping];
    } if (
      a?.length > 0 && typeof logicalGroupingDisplay[a[0]?.logicalGrouping] === 'number'
      && b?.length > 0 && logicalGroupingDisplay[b[0]?.logicalGrouping] == null
    ) {
      return -1;
    } if (
      a?.length > 0 && logicalGroupingDisplay[a[0]?.logicalGrouping] == null
      && b?.length > 0 && typeof logicalGroupingDisplay[b[0]?.logicalGrouping] === 'number'
    ) {
      return 1;
    }
    return a?.length > 0 && b?.length > 0 && a[0]?.logicalGrouping > b[0]?.logicalGrouping ? 1 : -1;
  });

  return data;
};

export const getAttribute = (template, path) => {
  const splitPath = path.split('.');
  if (splitPath?.length > 0) {
    var temp = template?.filter((item) => item?.name == splitPath[0])[0];

    for (var i = 0; i <= splitPath.slice(1)?.length; i++) {
      if (i == splitPath.slice(1).length) {
        return temp;
      }
      if (temp?.children && temp?.children?.filter((item) => item?.name == splitPath.slice(1)[i])?.length > 0) temp = temp?.children?.filter((item) => item?.name == splitPath.slice(1)[i])[0];
    }
  }
  if (temp.type == 'attribute' && temp.name == splitPath[splitPath.length - 1]) {
    return temp;
  }
};

export const checkTPDEnabledAtClass = (template, path) => {
  const splitPath = path;
  if (splitPath.length > 0) {
    let temp = template?.filter((item) => item?.name == splitPath[0])?.length > 0 ? template.filter((item) => item?.name == splitPath[0])[0] : [];

    for (var i = 0; i <= splitPath.slice(1)?.length; i++) {
      if (temp?.children && temp?.children?.filter((item) => item?.name == splitPath.slice(1)[i])?.length > 0) temp = temp?.children?.filter((item) => item?.name == splitPath.slice(1)[i])[0];
      if (checkTPDAtClassLevel(temp) && isRepeatableByClass(temp?.repeatableByClass)) {
        path.splice(i + 2, 0, 'value');
        break;
      }
    }
  }
  return path;
};
