import React from 'react';
import { withTranslation } from 'react-i18next';

import { NBB_HEIRARCHY_NAVIGATION_WARNING } from '../../../../../constant/constant';

function WarningModal(props) {
  const { isOpen, t } = props;
  return (
    <div className={isOpen ? 'modal approve-modal fade show' : 'modal approve-modal fade'} style={{ display: isOpen ? 'block' : '' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{t('Warning')}</h4>
            <button type="button" onClick={props.closeModal} className="close close-icon" data-dismiss="modal">
              <img src="/assets/images/icons-svg/close.svg" alt="" />
            </button>
          </div>
          <div className="modal-body">
            <div className="alert alert-warning">{t(NBB_HEIRARCHY_NAVIGATION_WARNING)}</div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={props.handleApproveAndContinue}>{t('Continue')}</button>
            <button className="btn btn-secondary" onClick={props.closeModal}>{t('Cancel')}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(WarningModal);
