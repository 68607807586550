// --------------------------------------------uncertified-product------------------------------------------------
export const CREATE_PRODUCT_API = '/sde/create-product';
export const CLONE_PRODUCT_API = '/sde/product-clone';
export const PRODUCT_CLONE_CHECK_API = '/sde/get-copy-product-restriction';
export const GET_BRANDS_API = '/get-brands';
export const GET_TARGET_MARKET_SUBCODE_API = '/get-subcodes-from-target-markets';
export const DISCARD_PRODUCT_API = '/sde/discard-product';
export const PREPARE_SAMPLE_TEMPLATE_API = '/asset/prepare-product-template';
export const DOWNLOAD_SAMPLE_TEMPLATE_API = '/asset/download-product-template';
export const IMPORT_PRODUCTS_API = '/asset/process-import-products';
export const IMPORT_PRODUCTS_TOPUP_DATA = '/asset/process-topup-import';
export const GET_UNCERTIFIED_TEMPLATE_DATA = '/sde/get-template-data';
export const CHECK_UNCERTIFIED_LOCK_PRODUCT = '/sde/check-lock-product';
export const UNLOCK_UNCERTIFIED_PRODUCT = '/sde/unlock-product';
export const LOCK_UNCERTIFIED_PRODUCT = '/sde/lock-product';
export const GET_UNCERTIFIED_PRODUCT_COMMENTS = '/sde/get-product-comments';
export const ADD_UNCERTIFIED_PRODUCT_COMMENTS = '/sde/add-product-comment';
export const GET_UNCERTIFIED_IMAGE_TYPE = '/asset/image-shot-type';
export const GET_UNCERTIFIED_DOCUMENT_TYPE = '/asset/document-type';
export const GET_UNCERTIFIED_PRODUCT_VERSIONS = '/sde/get-product-versions';
export const GET_UNCERTIFIED_VERSION_DATA = '/sde/get-version-data';
export const UNCERTIFIED_PUBLISH_VERSION = '/sde/publish-version';
export const GET_UNCERTIFIED_VERSION_DIFF = '/sde/get-version-diff';
export const CHECK_UNCERTIFIED_PRODUCT_VERSION = '/sde/check-product-version';
export const PREPARE_UNCERTIFIED_PRODUCT_ASSETS_ZIP = '/asset/prepare-asset-zip-download';
export const PROCESS_UNCERTIFIED_PRODUCT_ASSETS_ZIP = '/asset/process-asset-zip-download';
export const DOWNLOAD_UNCERTIFIED_PRODUCT_ASSETS_ZIP = '/asset/bulk-assets-download';
export const SINGLE_UNCERTIFIED_PRODUCT_ASSETS_ZIP = '/download/product-asset-zip';
export const UPDATE_UNCERTIFIED_PRODUCT = '/sde/update-product';
export const PREPARE_UNCERTIFIED_PRODUCT_EXPORT_ZIP = '/asset/prepare-export-products';
export const PROCESS_UNCERTIFIED_PRODUCT_EXPORT_ZIP = '/asset/process-export-products';
export const PROCESS_BULK_UNCERTIFIED_PRODUCT_EXPORT_ZIP = '/asset/bulk-products-download';
export const GET_UNCERTIFIED_PRODUCT_LIST = '/sde/get-total-product-list';
export const UNCERTIFIED_PRODUCT_BULK_APPROVE = '/sde/uncertified-product-bulkApprove';
export const REQUEST_UNCERTIFIED_PRODUCT_DELIST = '/sde/uncertified-product-delist';
export const REQUEST_UNCERTIFIED_PRODUCT_RELIST = '/sde/uncertified-product-relist';
export const GET_PRODUCT_COUNT_BY_STATUS = '/dashboard/get-product-count-by-status';
export const GET_LOOKUP_VALUES_BY_ITEM_TYPE_ID = '/get-lookup-values-by-item-type-Id';
export const GET_LOOKUP_UNIT_VALUES_BY_ITEM_TYPE_ID = '/get-lookup-unit-values-by-item-type-Id';
export const GET_LIST_FILTER_DATA = '/get-filters';

// --------------------------------------------certified-product--------------------------------------------------
export const GET_CERTIFIED_TEMPLATE_DATA = '/certified/get-template-data';
export const CHECK_CERTIFIED_LOCK_PRODUCT = '/certified/check-lock-product';
export const UNLOCK_CERTIFIED_PRODUCT = '/certified/unlock-product';
export const LOCK_CERTIFIED_PRODUCT = '/certified/lock-product';
export const GET_CERTIFIED_PRODUCT_COMMENTS = '/certified/get-product-comments';
export const ADD_CERTIFIED_PRODUCT_COMMENTS = '/certified/add-product-comment';
export const GET_CERTIFIED_IMAGE_TYPE = '/asset/certified-image-shot-type';
export const GET_CERTIFIED_DOCUMENT_TYPE = '/asset/certified-document-type';
export const GET_CERTIFIED_PRODUCT_VERSIONS = '/certified/get-product-versions';
export const GET_CERTIFIED_VERSION_DATA = '/certified/get-version-data';
export const CERTIFIED_PUBLISH_VERSION = '/certified/publish-version';
export const GET_CERTIFIED_VERSION_DIFF = '/certified/get-version-diff';
export const CHECK_CERTIFIED_PRODUCT_VERSION = '/certified/check-product-version';
export const PREPARE_CERTIFIED_PRODUCT_ASSETS_ZIP = '/asset/prepare-certified-asset-zip-download';
export const PROCESS_CERTIFIED_PRODUCT_ASSETS_ZIP = '/asset/process-certified-asset-zip-download';
export const DOWNLOAD_CERTIFIED_PRODUCT_ASSETS_ZIP = '/asset/bulk-certified-assets-download';
export const SINGLE_CERTIFIED_PRODUCT_ASSETS_ZIP = '/download/certified-product-asset-zip';
export const UPDATE_CERTIFIED_PRODUCT = '/certified/update-product';
export const PREPARE_CERTIFIED_PRODUCT_EXPORT_ZIP = '/asset/prepare-certified-products-export';
export const PROCESS_CERTIFIED_PRODUCT_EXPORT_ZIP = '/asset/process-certified-products-export';
export const PROCESS_BULK_CERTIFIED_PRODUCT_EXPORT_ZIP = '/asset/bulk-certified-products-download';
export const GET_CERTIFIED_PRODUCT_LIST = '/certified/get-total-product-list';
export const UPDATE_PRODUCT_SUPPLIER = '/certified/update-revision-product';
export const GET_REJECTED_ATTRIBUTE = '/certified/get-rejected-attributes';
export const SAVE_REJECTED_ATTRIBUTE = '/certified/save-rejected-attributes';
export const SAVE_CHANNEL_STATUS = '/certified/save-channel-status';
export const GET_CHANNEL_STATUS = '/certified/get-channel-status';
export const SAVE_CHANNEL_COMMENTS = '/certified/save-channel-comments';
export const CHANGE_CORE_PRODUCT_STATUS = '/certified/change-product-status';
export const GET_AWAITING_APPROVAL_COMMENT = '/certified/get-approval-rejection-comment';
// export const GET_ACTIVE_CHANNELS = "/certified/get-active-channels";
export const CERTIFIED_PRODUCT_BULK_APPROVE = '/certified/product-bulk-approve';
export const CERTIFIED_PRODUCT_BULK_REJECT = '/certified/product-bulk-reject';
export const CERTIFIED_GET_TARGATE_USAGE_OPTION = '/certified/get-target-usage-options';
export const GET_EU11_OPTIONS = '/certified/get-eu11-options';
export const REQUEST_CERTIFIED_PRODUCT_DELIST = '/certified/certified-product-delist';
export const GET_RETAILERS = '/certified/get-retailers';
export const ADD_RETAILER = '/certified/add-retailer';
export const DELETE_RETAILER = '/certified/delete-retailer';
export const PUBLISH_GDSN_CERTIFIED_PRODUCT_DOWNLOAD = '/certified/publish-to-gdsn';
export const CHECK_GDSN_LICENSE_CERTIFIED_PRODUCT = '/certified/check-gdsn-license';

// -------------------------------------------------nbb-product----------------------------------------------------
export const GET_SUBCODE_LIST = '/nbb-product/subscriptions';
export const GET_TARGET_MARKET_FROM_SUBCODE = (id) => `/nbb-product/subscription/${id}/target-markets`;
export const NBB_PRODUCT_BULK_APPROVE = '/nbb-products/bulk-approve-products';
export const NBB_PRODUCT_BULK_REJECT = '/certified/product-bulk-approve';

// export const GET_TRADE_ITEM_UNIT_DESCRIPTOR_CODE = (codeName) => {
//     return `/hybrid/code/list?codeName=${codeName}`;
// };
// export const GET_GPC_CATEGORY = '/nbb-products/get-gpc-category';
export const GET_RECIPIENTS = '/nbb-product/retailer/search';
export const SAVE_PRODUCT = '/nbb-product';
export const CHANGE_PRODUCT_STATUS = '/nbb-products/update-workflow';
export const GET_LOWER_LEVEL_PRODUCT = '/nbb-products';
export const GET_BASIC_DATA = (id) => `/nbb-product/basic-detail/${id}`;
export const GET_PRODUCT_SEARCH = '/nbb-product/search';
export const GET_ALL_PRODUCT = '/nbb-product/total-product-list';
export const DELETE_PRODUCT = '/nbb-product/delete-product';
export const CHECK_PRODUCT_STATUS = '/nbb-product/check-product-status';

export const GET_ADVANCE_FILTER_DROPDOWN = '/get-advance-filter-dropdowns';
export const GET_NBB_PRODUCT_TEMPLATE = '/nbb-product/template';
export const GET_PRODUCT_HIERARCHY = (id) => `/nbb-product/${id}/hierarchy`;
export const GET_PRODUCT_TPD_RETAILERS = '/nbb-product/get-retailers-tpd';
export const GET_PRODUCT_DATA = '/nbb-product/details';
export const GET_PRODUCT_PUSBLISH_DATA = '/nbb-product/get-productPublishVersion';
export const GET_BASIC_TEMPLATE_ATTRIBUTES = '/nbb-product/basic-template';
export const GET_CIN_LOG_DATA = '/nbb-products/cin-message-log';
export const GET_CIC_LOG_DATA = '/nbb-products/cic-message-log';
export const GET_NBB_PRODUCT_COMMENTS = '/nbb-products/get-comments';
export const ADD_NBB_PRODUCT_COMMENTS = '/nbb-products/save-nbbProduct-comments';
export const CHECK_BENELUX_LOCK_PRODUCT = '/nbb-products/check-lock-product';
export const UNLOCK_BENELUX_PRODUCT = '/nbb-products/unlock-product';
export const LOCK_BENELUX_PRODUCT = '/nbb-products/lock-product';
export const MIGRATE_PRODUCT = '/nbb-product/migrate-product';
export const NBB_PRODUCT_PREPARE_EXPORT = '/import-benelux/prepare-nbb-export-products';
export const NBB_PRODUCT_PROCESS_EXPORT = '/import-benelux/process-nbb-export-products';
export const NBB_PRODUCT_BULK_EXPORT = '/import-benelux/bulk-nbb-products-download';
export const PREPARE_NBB_SAMPLE_TEMPLATE_API = '/import-benelux/prepare-benelux-products-template';
export const NBB_TARGET_MARKET_API = '/import-benelux/get-nbb-target-market';
export const IMPORT_NBB_PRODUCTS_API = '/import-benelux/import-data';
export const GET_PUBLISH_STATUS_LOG_API = '/nbb-products/product-status-log';
export const PREPARE_NBB_PRODUCT_IMAGE_ZIP = '/asset/prepare-nbb-certified-image-zip-download';
export const PROCESS_NBB_PRODUCT_IMAGE_ZIP = '/asset/process-nbb-certified-image-zip-download';
export const DOWNLOAD_NBB_PRODUCT_IMAGE_ZIP = '/asset/bulk-nbb-certified-images-download';
export const GET_SUPPLIER_GLN = '/nbb-product/search-supplier-gln';
export const GET_HYBRID_PRODUCT_VERSIONS = '/nbb-products/get-versions';
export const GET_HYBRID_PRODUCT_VERSION_DETAILS = '/nbb-products/get-product-version-details';
export const GET_HYBRID_PRODUCT_VERSION_DIFFERENCE = '/nbb-products/get-versions-difference';

// --------------------------nbb-dashboard------------------------------------------------------------------
export const GET_HYBRID_COMPLETENESS_SCORE = '/dashboard/hybrid/get-completeness-score';
export const GET_HYBRID_PRODUCT_COUNT_BY_STATUS = '/dashboard/hybrid/get-product-count-by-status';
export const GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL = '/dashboard/hybrid/published-products-count-by-channel';
export const GET_RECENT_EDITED_NBB_PRODUCT = '/dashboard/hybrid/latest-products';
export const GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA = '/dashboard/hybrid/awaiting-brandbank-captured-data-review-product';
export const GET_HYBRID_PRODUCT_VALIDATION_COUNT = '/dashboard/hybrid/get-product-validation-count';
export const GET_DASHBOARD_RSC_PRODUCT_COUNT = '/dashboard/hybrid/get-rsc-products-count';
export const GET_DASHBOARD_COUNT_BY_CERTIFICATION = '/dashboard/get-count-by-certification';

// --------------------------awaiting-brandbank-captured-data-review----------------------------------------
export const GET_AWAITING_BRANDBANK_CAPTURED_TEMPLATE_DATA = '/nbb-core-product/get-template-data';
export const GET_AWAITING_APPROVAL_VIEW_PRODUCT_VERSIONS = '/nbb-core-product/get-product-versions';
export const GET_AWAITING_BRANDBANK_CAPTURED_PRODUCT_LIST = '/nbb-core-product/get-total-product-list';
export const AWAITING_BRANDBANK_CAPTURED_PRODUCT_BULK_APPROVE = '/nbb-core-product/product-bulk-approve';
export const CHANGE_APPROVAL_VIEW_PRODUCT_STATUS = '/nbb-core-product/change-product-status';
export const MARK_AS_READ_FAILED_STATUS_NOTIFICATION = '/asset/change-failed-status';
export const GET_CORE_PRODUCT_DATA = '/nbb-core-product/get-product-data';
export const GET_CORE_PRODUCT_LIST = '/nbb-core-product/get-product-list';
export const GET_CORE_DYNAMIC_CATEGORIES = '/nbb-core-product/get-dynamic-categories';
export const AWAITING_BRANDBANK_CAPTURED_PRODUCT_BULK_REJECT = '/nbb-core-product/product-bulk-reject';
export const GET_AWAITING_BRANDBANK_APPROVAL_COMMENT = '/nbb-core-product/get-rejection-comments';
export const REQUEST_AWAITING_BRANDBANK_CAPTURED_PRODUCT_DELIST = '/nbb-core-product/nbb-product-delist';
export const REQUEST_CHECK_PRODUCT_HIERARCHY = '/nbb-core-product/check-product-hierarchy';

export const GET_CONNECT_PLUS_API = 'https://api.devbrandbank.com/userapi/getServices';
export const GET_PARENT_PRODUCT_HIERARCHY = 'nbb-product/get-product-hierarchy';
export const BULK_DELIST_PRODUCTS = 'nbb-product/bulk-product-delist-request';

// ---------------------------------reports-----------------------------------------------------

export const GET_PUBLICATION_SUMMARY_REPORTS = '/report/publication-summary-report';
export const EXPORT_PUBLICATION_SUMMARY_REPORTS = '/report/export/publication-summary-report';

export const GET_PUBLICATION_DETAIL_REPORTS = '/report/publication-detail-report';
export const EXPORT_PUBLICATION_DETAIL_REPORTS = '/report/export/publication-detail-report';

export const GET_MERGE_DETAIL_REPORTS = '/report/merge-detail-report';
export const EXPORT_MERGE_DETAIL_REPORT = '/report/export/merge-detail-report';

export const GET_SUPPLIER_SPECIFIC_ATTRIBUTE = 'nbb-product/supplier-specific-attribute';

// ---------------------------------post-publication-view------------------------------------------

export const GET_POST_PUBLICATION_DATA = 'nbb-product/get-post-publication-data';
export const GET_POST_PUBLICATION_TEMPLATE = 'nbb-core-product/get-template-data';

// ----------------------------------SPV2 Connect Max----------------------------------------------
export const DOWNLOAD_ASSETS_ZIP = (productId) => `/download/process-topup-certified-assets-zip-download?downloadId=${productId}`;
export const GET_BRANDBANK_ASSETS = (productId) => `/asset/certified-product-images?productId=${productId}&sortBy=shotType&order=ASC`;
export const GET_USER_UPLOADED_ASSETS = '/asset/get-topup-library-asset';
export const REMOVE_USER_UPLOADED_ASSETS = '/asset/remove-topup-library-assets';
export const GET_RETAILERS_FOR_TOPUP = '/certified/get-retailers-for-topup';
export const GET_SAVED_LAYOUTS = '/asset/get-prouct-topup-layouts';
export const UPLOAD_TOPUP_LIBRARY_ASSET_VIDEO_URL = '/asset/topup-library-asset-upload-video-url';
export const UPLOAD_TOPUP_LIBRARY_ASSET_VIDEO_MP4 = '/asset/topup-library-asset-upload-video-mp4';
export const UPLOAD_TOPUP_LIBRARY_ASSET_IMAGE = '/asset/topup-library-asset-upload-image';
export const GET_ASSET_FILE_SIZE = '/asset/get-connect-max-asset-size-limit';

// -----------------------Custom Extract--------------------------------------------
export const EXPORT_GET_ATTRIBUTE_DATA = '/data/extract/get-certified-attributes';
export const SAVE_CUSTOM_ATTRIBUTE_TEMPLATE = '/data/extract/custom-templates';
export const GET_TEMPLATE_LIST = '/data/extract/get-templates-list';
export const DELETE_TEMPLATE = (id) => `/data/extract/custom-templates/${id}`;
export const GET_TEMPLATE_INFO = '/data/extract/get-template-info';
export const UPDATE_CUSTOM_ATTRIBUTE_TEMPLATE = (id) => `/data/extract/custom-templates/${id}`;
export const PROCESS_CUSTOM_BULK_PRODUCT_EXPORT_ZIP = '/data/extract/bulk-data-extract-download';
export const PREPARE_CUSTOM_TEMPLATE_PRODUCT_DATA = '/data/extract/prepare-custom-template-product-data-export';
export const PROCESS_CUSTOM_TEMPLATE_PRODUCT_DATA_EXPORT = '/data/extract/process-custom-template-product-data-export';

// ------------------------------Image Extract------------------------------------
export const CREATE_TEMPLATE = '/image/extract/create-template';
export const GET_IMAGE_TEMPLATE_LIST = '/image/extract/get-templates';
export const UPDATE_IMAGE_TEMPLATE = '/image/extract/update-template';
export const DELETE_IMAGE_TEMPLATE = (tempId) => `/image/extract/delete-template?templateId=${tempId}`;
export const GET_IMAGE_CONFIGURATIONS = '/image/extract/get-configurations';
