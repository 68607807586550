import React from 'react';

export function Header(props) {
  const { resizeFlag, t, deprecateModal } = props;
  return (
    <div className="validation_header">
      <div
        className="title"
        onMouseDown={resizeFlag ? props.onMouseDown : null}
        style={{ cursor: resizeFlag ? 'move' : '' }}
      >
        <h3>{deprecateModal ? t('This product have deprecated field(s)') : t('Validations')}</h3>
      </div>
      <div className="action">
        {!resizeFlag ? (
          <button className="close close-icon mr-1" onClick={props.handleResize}>
            <img
              src="/assets/images/icons-svg/minimize.svg"
              onClick={props.handleResize}
              alt="minimize"
            />
          </button>
        ) : (
          <button className="close close-icon mr-1" onClick={props.handleResize}>
            <img
              src="/assets/images/icons-svg/maximize.svg"
              onClick={props.handleResize}
              alt="maximize"
            />
          </button>
        )}
        <button className="close close-icon" onClick={props.closeModal}>
          <img
            src="/assets/images/icons-svg/close.svg"
            onClick={props.closeModal}
            alt=""
          />
        </button>
      </div>
    </div>
  );
}
