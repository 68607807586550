import React from 'react';

import * as Constants from '../../../../../constant/constant';
import Tooltip from '../../../../tooltip/Tooltip';

export function Filter(props) {
  const { t } = props;
  const _handleKeyDown = (e, value, name) => {
    if (e.key === 'Enter') {
      props.filterValidation(value, name);
    }
  };
  return (
    <ul className="validation-body-list ">
      <li>
        <div className="form-check">
          <label>
            <span className={`custom-checkbox ${(props.errorCheck) ? 'checked' : ''}`} onClick={() => props.filterValidation(props.errorCheck, 'errorCheck')} tabIndex="0" onKeyDown={(e) => _handleKeyDown(e, props.errorCheck, 'errorCheck')} />
            <input type="checkbox" value="" />
            <span className="validation_list">
              {t('Errors')}
              <Tooltip
                message={Constants.NBB_PRODUCT_VALIDATION_TOOLTIP.error}
                position="bottom"
              />
            </span>
          </label>
        </div>
      </li>
      <li>
        <div className="form-check">
          <label>
            <span className={`custom-checkbox ${(props.warningCheck) ? 'checked' : ''}`} onClick={() => props.filterValidation(props.warningCheck, 'warningCheck')} tabIndex="0" onKeyDown={(e) => _handleKeyDown(e, props.warningCheck, 'warningCheck')} />
            <input type="checkbox" value="" />
            <span className="validation_list">
              {t('Warnings')}
              <Tooltip
                message={Constants.NBB_PRODUCT_VALIDATION_TOOLTIP.warning}
                position="bottom"
              />
            </span>
          </label>
        </div>
      </li>
      <li>
        <div className="form-check">
          <label>
            <span className={`custom-checkbox ${(props.announcementCheck) ? 'checked' : ''}`} onClick={() => props.filterValidation(props.announcementCheck, 'announcementCheck')} tabIndex="0" onKeyDown={(e) => _handleKeyDown(e, props.announcementCheck, 'announcementCheck')} />
            <input type="checkbox" value="" />
            <span className="validation_list">
              {t('Announcement')}
              <Tooltip
                message={Constants.NBB_PRODUCT_VALIDATION_TOOLTIP.announcement}
                position="bottom"
              />
            </span>
          </label>
        </div>
      </li>
    </ul>
  );
}
