import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { SUPPLIER_PREFERRED_PRODUCT_NAME_TOOLTIP_LENGTH } from '../../constant/constant';

import classes from './Tooltip.module.css';

function Tooltip(props) {
  const { t } = props;
  const [displayTooltip, setDisplayTooltip] = useState(false);
  let clsName = [classes.TooltipBubble, `custom-tooltip-${props.position}`].join(' ');
  const charLength = (props.message) ? props.message.length : 0;
  let tooltipWidth = 400;
  if (charLength > SUPPLIER_PREFERRED_PRODUCT_NAME_TOOLTIP_LENGTH) {
    tooltipWidth = SUPPLIER_PREFERRED_PRODUCT_NAME_TOOLTIP_LENGTH;
    clsName = [classes.TooltipBubble, 'custom-tooltip-left-top'].join(' ');
  }
  const boxLength = (charLength > 300) ? tooltipWidth : charLength;
  const customStyle = {
    minWidth: (props.width) ? props.width : boxLength,
    textAlign: 'center',
  };

  const hideTooltip = () => {
    setDisplayTooltip(false);
  };

  const showTooltip = () => {
    setDisplayTooltip(true);
  };

  const createMarkup = (msg) => ({ __html: DOMPurify.sanitize(msg) });
  return (
    <span
      className={classes.Tooltip}
      onMouseLeave={hideTooltip}
    >
      {displayTooltip
              && (
              <div className={clsName}>
                <div className={classes.TooltipMessage} style={customStyle} dangerouslySetInnerHTML={createMarkup(t(props.message))} />
              </div>
              )}
      <span
        className={classes.TooltipTrigger}
        onMouseOver={showTooltip}
      >
        {props.children}
        {!props?.displayName && <img src="/assets/images/icons-svg/info.svg" height="18px" width="18px" className={`custom-info-icon mr-1 ${classes.TooltipInfo}`} aria-hidden="true" />}
      </span>
    </span>
  );
}

export default withTranslation()(Tooltip);
