import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';

import RootRouter from './Router/RootRouter';
import store from './redux/Store';
import UploadProgress from './Component/uncertified-asset-components/UploadProgress/UploadProgress';
import LiveChatWidget from './Component/Modal/LiveChatWidget';
import ValidationModal from './ui/modal/nbb/edit/validation/ValidationModal';

const hotJarID = process.env.REACT_APP_HOTJAR_ID;
const hotJarSV = process.env.REACT_APP_HOTJAR_SV;
const GTMCode = process.env.REACT_APP_GTM_TRACKING_CODE;

const tagManagerArgs = {
  gtmId: GTMCode,
};

class App extends Component {
  componentDidMount() {
    if (hotJarID && hotJarSV) {
      hotjar.initialize(hotJarID, hotJarSV);
    }
    if (GTMCode) {
      TagManager.initialize(tagManagerArgs);
    }
  }

  render() {
    return (
      <>
        {/* <Suspense fallback={<Loader />}> */}
        <Provider store={store}>
          <RootRouter />
          <UploadProgress />
          <NotificationContainer />
          <LiveChatWidget />
          {/* <ValidationModal
          history={history} /> */}
        </Provider>
        {/* </Suspense> */}
      </>

    );
  }
}

export default App;
