import React, { useState } from 'react';

import { getRetailerName } from '../../../../../Utils/utils';
import Tooltip from '../../../../tooltip/Tooltip';
import { getAttribute } from '../../../../../Utils/Nbb/sortNbbTemplateHandler';

export function Accordion(props) {
  const {
    data, accordionIndex, activeAccordion, t, template, componentTemplate,
  } = props;
  const error = []; const warning = []; const announcement = [];
  const validationData = data && data.data.map((vData) => {
    if (vData.type === 'error') {
      error.push({ ...vData, productId: data.id });
    } else if (vData.type === 'warning') {
      warning.push({ ...vData, productId: data.id });
    } else {
      announcement.push({ ...vData, productId: data.id });
    }
  });
  const pValidation = {
    error: (props.errorCheck) ? error : [],
    warning: (props.warningCheck) ? warning : [],
    announcement: (props.announcementCheck) ? announcement : [],
  };
  const _handleKeyDown = (e, attrList) => {
    if (e.key === 'Enter') {
      props.resolveMandatoryField(attrList, data.gtin);
    }
  };

  const _handleKeyDownTab = (e, accordionIndex) => {
    if (e.key === 'Enter') {
      props.handleToggleAccordion(accordionIndex);
    }
  };

  const getAttributeList = (vAttributeList, pimRuleId) => {
    const { productId } = vAttributeList;
    const vAttribute = (vAttributeList && vAttributeList.attributes) ? vAttributeList.attributes : [];
    let cmplabel = '';

    return (
      <>
        {
          vAttribute.map((attr, inx) => {
            const getDisplayNameInUi = attr?.component ? getAttribute(componentTemplate, attr?.path)?.displayNameInUi : getAttribute(template, attr.path)?.displayNameInUi;
            cmplabel = (!cmplabel && attr.component && attr.componentNumber) ? (
              <>
                {' '}
                <b className="cmp-attr-style">
                  {`Component - ${attr.componentNumber} :`}
                  {' '}
                </b>
                {' '}
                {attr.label}
              </>
            ) : attr.label;
            return (
              <React.Fragment key={`valdation-attr-${inx}`}>
                <span>
                  {(inx) ? <b>|</b> : ''}
                  <img
                    src="/assets/images/icons-svg/asset-chat.svg"
                    alt=""
                    height="16"
                    width="16"
                  />
                  <span className="cursor-pointer" onClick={(e) => props.renderToAttribute({ ...attr, productId }, pimRuleId)}>{getDisplayNameInUi ? <Tooltip displayName message={attr?.name} position="top">{cmplabel}</Tooltip> : cmplabel}</span>
                </span>
              </React.Fragment>
            );
          })
        }
      </>
    );
  };

  const getRetailerIds = (attributes, retailers) => {
    const rscAttributes = attributes.filter((item) => Object.keys(item).includes('retailerId'));
    if (rscAttributes?.length > 0) {
      return ([...new Set(rscAttributes?.map((item) => getRetailerName(item.retailerId, retailers)))]).join(',');
    }
    return null;
  };

  const attributeLayoutRender = (attrList, index) => {
    function replacer(r) {
      return function(match, p1, p2, p3, offset, string) {
        
        // Determine which translation source to use
        const translationSource = (r && typeof r === "object")? r : props.errorMsgTranslations;
        
        if (typeof translationSource === "object" && Object.keys(translationSource)?.length > 0 && translationSource[p2]) {
          return t(translationSource[p2]);
        } else {
          // With Bracket
          return p2;
        }
      };
    }
    const ruleId = (attrList.ruleId) ? ` - (${attrList.ruleId})` : '';
    const retailerName = getRetailerIds(attrList.attributes, props.retailers) ? ` (${getRetailerIds(attrList.attributes, props.retailers)})` : '';
    const pimRuleId = (attrList.ruleId) ? attrList.ruleId : '';
    let responseMessage = t(pimRuleId);
    responseMessage = (responseMessage === pimRuleId || !responseMessage) ? attrList.message : responseMessage;
    const attrValMsg = (responseMessage) ? responseMessage.toString().replace(/([\[])(.+?)([\]])/g, replacer(attrList?.r)) + ruleId.replace('pimRule', 'ValidationRule') + retailerName : '';
    return (
      <div key={`valiation_${index}`} className={`validation-modal-section validation-seperator ${attrList.type} ${(index) ? 'mt-0' : ''}`}>
        <ul>
          <li>
            <div className="form-check">
              <label className="form-check-label validation-check">
                <span className="custom-checkbox" tabIndex="0" onClick={() => props.resolveMandatoryField(attrList, data.gtin)} onKeyDown={(e) => _handleKeyDown(e, attrList, data.gtin)} />
                <input type="checkbox" value="" />
              </label>
            </div>
            <span className="validation-TestSection">
              {getAttributeList(attrList, pimRuleId)}
            </span>
          </li>
          {/* <li>
            {getAttributeList(attrList)}
          </li> */}
        </ul>
        <p>
          {t(attrValMsg)}
        </p>
      </div>
    );
  };

  return (
    <div
      className={
      (accordionIndex === activeAccordion)
        ? 'nbb-accordion mx-0 mb-0'
        : 'nbb-accordion mx-0 mb-0 card-collapsed'
    }
    >
      <div
        className="nbb-accordion-header header-elements-inline cursor-pointer"
        onClick={() => props.handleToggleAccordion(accordionIndex)}
      >
        <div className="card-title">
          {data.gtin}
          <span className="pl-1">
            <img
              src={`/assets/images/icons-svg/hierarchy/${data?.tradeItemDescriptor.toLowerCase()}.svg`}
              alt=""
              className="model-img"
            />
          </span>
        </div>
        <div className="header-elements">
          <div className="list-icons">
            <a
              href={void 0}
              aria-label="up-down"
              className={(activeAccordion !== accordionIndex) ? 'rotate-180' : ''}
              data-action="collapse"
              onClick={() => props.handleToggleAccordion(accordionIndex)}
              onKeyDown={(e) => _handleKeyDownTab(e, accordionIndex)}
              tabIndex="0"
            />
          </div>
        </div>
      </div>
      <div
        className="card-body p-0"
        style={{ display: (activeAccordion !== accordionIndex) ? 'none' : 'block' }}
      >
        {
        pValidation && pValidation.error.map((attrList, index) => attributeLayoutRender(attrList, index))
      }
        {
        pValidation && pValidation.warning.map((attrList, index) => attributeLayoutRender(attrList, index))
      }
        {
        pValidation && pValidation.announcement.map((attrList, index) => attributeLayoutRender(attrList, index))
      }
      </div>
    </div>
  );
}
